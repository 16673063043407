import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_2 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_Icons = _resolveComponent("Icons")!
  const _directive_sanitize_html = _resolveDirective("sanitize-html")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("p", {
      class: `text-${_ctx.fontSize}px font-${_ctx.fontWeight} text-${_ctx.textColor}`
    }, _toDisplayString(_ctx.text), 3),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_OverlayPanel, {
        ref: "opTooltip",
        appendTo: "body"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", { hide: _ctx.hide }),
          (_ctx.isTooltip)
            ? (_openBlock(), _createBlock("div", {
                key: 0,
                class: "font-normal text-12px text-black-lp-300 font-montserrat",
                style: `width: ${_ctx.width}px`
              }, [
                _withDirectives(_createVNode("div", null, null, 512), [
                  [_directive_sanitize_html, _ctx.description]
                ])
              ], 4))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 512),
      (_ctx.icon)
        ? (_openBlock(), _createBlock("div", {
            key: 0,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleTooltipHeader($event))),
            class: 
          `${_ctx.text ? '' : 'mt-2'} ${
            _ctx.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          }`
        
          }, [
            _createVNode(_component_Icons, {
              name: _ctx.icon,
              class: "w-5 text-gray-lp-600"
            }, null, 8, ["name"])
          ], 2))
        : (_openBlock(), _createBlock("div", {
            key: 1,
            onMouseenter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleTooltipHeader($event))),
            onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleTooltipHeader($event))),
            class: `cursor-pointer ${_ctx.adjustedPosition}`
          }, [
            _createVNode(_component_Icons, {
              name: "info",
              class: 
            `${
              _ctx.isTooltip ? 'text-red-lp-100' : _ctx.iconColor || 'text-gray-lp-600'
            } ${_ctx.widthIcon}`
          
            }, null, 8, ["class"])
          ], 34))
    ])
  ]))
}